import { ApiClient } from '@je-pc/api'
import { tokenTypesConfig } from '@je-pc/auth'
import {
  SITECORE_URL,
  SITECORE_API_KEY,
  TENANT,
  SMARTGATEWAY_URL,
} from '../constants'

const http = new ApiClient(SITECORE_URL, false, true)
const httpKeyCloakClient = new ApiClient(SMARTGATEWAY_URL, true, true, {
  tokenType: tokenTypesConfig.list.keycloak,
})

export const getArticle = (sectionName, articleName) => {
  return http.get(
    `/sitecore/api/layout/render/jss?item=%2F${sectionName}/${articleName}&sc_apikey=${SITECORE_API_KEY}&sc_site=websitepartner${TENANT.toUpperCase()}`
  )
}

export const getStaticContent = (type) => {
  return http.get(
    `/sitecore/api/layout/render/jss?item=%2F${type}&sc_apikey=${SITECORE_API_KEY}&sc_site=websitepartner${TENANT.toUpperCase()}`
  )
}

export const getSupportRequests = (params) => {
  return httpKeyCloakClient.get(
    '/applications/partnerhelp/{tenant}/{restaurantId}/support/requests',
    {
      params: {
        ...params,
        limit: 10,
      },
    }
  )
}

export const getSupportRequestTypes = () => {
  return httpKeyCloakClient.get(
    'applications/partnerhelp/{tenant}/{restaurantId}/support/requests/types'
  )
}

export const submitSupportRequest = (params) => {
  return httpKeyCloakClient.post(
    'applications/partnerhelp/{tenant}/{restaurantId}/support/requests',
    params
  )
}

export const getSettings = (params) => {
  return httpKeyCloakClient.get(
    `/applications/partnercentre/{tenant}/{restaurantId}/settings?settings=${params}`
  )
}
