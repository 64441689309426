import Vue from 'vue'
import Vuex from 'vuex'
import {
  getArticle,
  getStaticContent,
  getSupportRequests,
  getSupportRequestTypes,
  getSettings,
} from '../services/http.js'
import {
  GET_ARTICLE,
  GET_STATIC_CONTENT,
  GET_SUPPORT_REQUESTS,
  GET_SUPPORT_REQUEST_TYPES,
  FETCH_SETTINGS,
} from './actions.types'
import {
  SET_ARTICLE,
  SET_STATIC_CONTENT,
  SET_SUPPORT_REQUESTS,
  SET_SUPPORT_REQUEST_TYPES,
  SET_USER,
  SET_SETTINGS,
} from './mutations.types'
import { getSitecoreResponse, getIdFromSectionName } from '../helpers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    business_essentials: {},
    account_security: {},
    common_questions: {},
    user: {},
    supportRequests: {
      paging: {},
      requests: [],
    },
    supportRequestTypes: [],
    settings: {},
  },
  mutations: {
    [SET_ARTICLE](state, data) {
      Vue.set(
        state[data.stateSectionName],
        data.articleName,
        getSitecoreResponse(data.response)
      )
    },
    [SET_STATIC_CONTENT](state, data) {
      Vue.set(state, data.type, getSitecoreResponse(data.response))
    },
    [SET_SUPPORT_REQUESTS](state, requests) {
      state.supportRequests = { ...requests }
    },
    [SET_SUPPORT_REQUEST_TYPES](state, types) {
      state.supportRequestTypes = types
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [SET_SETTINGS](state, settings) {
      state.settings = settings
    },
  },
  actions: {
    async [GET_ARTICLE]({ state, commit }, [sectionName, articleName]) {
      const stateSectionName = getIdFromSectionName(sectionName)
      if (state[stateSectionName][articleName]) {
        return state[stateSectionName][articleName]
      }
      const response = await getArticle(sectionName, articleName)
      commit(SET_ARTICLE, {
        stateSectionName,
        articleName,
        response: response.data,
      })
      return getSitecoreResponse(response.data)
    },
    async [GET_STATIC_CONTENT]({ state, commit }, type) {
      if (state[type]) {
        return
      }
      const response = await getStaticContent(type)
      commit(SET_STATIC_CONTENT, { type, response: response.data })
    },
    async [GET_SUPPORT_REQUESTS]({ commit }, params) {
      const response = await getSupportRequests(params)
      commit(SET_SUPPORT_REQUESTS, response.data)
    },
    async [GET_SUPPORT_REQUEST_TYPES]({ commit }) {
      const response = await getSupportRequestTypes()
      commit(SET_SUPPORT_REQUEST_TYPES, response.data)
    },
    async [FETCH_SETTINGS]({ state, commit }, settings) {
      const params = settings.join(',')
      const response = await getSettings(params)
      commit(SET_SETTINGS, { ...state.settings, ...response.data?.settings })
    },
  },
})
