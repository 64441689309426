<template>
  <div class="px-4 sm:px-6 pb-4 sm:pb-6">
    <pc-page-transition>
      <router-view />
    </pc-page-transition>
    <pc-toast />
  </div>
</template>

<script>
import { PcPageTransition } from '@je-pc/ui-components'

export default {
  components: {
    PcPageTransition,
  },
}
</script>
