const BusinessEssentials = () =>
  import(
    /* webpackChunkName: "businessEssentials" */ '../views/BusinessEssentials.vue'
  )
const BusinessEssentialsDetails = () =>
  import(
    /* webpackChunkName: "businessEssentialsDetails" */ '../views/BusinessEssentialsDetails.vue'
  )
const AccountSecurity = () =>
  import(
    /* webpackChunkName: "accountSecurity" */ '../views/AccountSecurity.vue'
  )
const AccountSecurityDetails = () =>
  import(
    /* webpackChunkName: "accountSecurityDetails" */ '../views/AccountSecurityDetails.vue'
  )
const CommonQuestions = () =>
  import(
    /* webpackChunkName: "commonQuestions" */ '../views/CommonQuestions.vue'
  )
const CommonQuestionsDetails = () =>
  import(
    /* webpackChunkName: "commonQuestionsDetails" */ '../views/CommonQuestionsDetails.vue'
  )
const SupportRequests = () =>
  import(
    /* webpackChunkName: "supportRequests" */ '../views/SupportRequests.vue'
  )
const SendMessage = () =>
  import(/* webpackChunkName: "sendMessage" */ '../views/SendMessage.vue')
const TermsAndConditions = () =>
  import(
    /* webpackChunkName: "termsAndConditions" */ '../views/TermsAndConditions.vue'
  )
const CookiesPolicy = () =>
  import(/* webpackChunkName: "cookiesPolicy" */ '../views/CookiesPolicy.vue')
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue')

const routes = [
  {
    path: '/support-requests',
    name: 'Support Requests',
    component: SupportRequests,
    meta: {
      name: 'support requests',
      group: 'business help',
    },
  },
  {
    path: '/send-message',
    name: 'Send Message',
    component: SendMessage,
    meta: {
      name: 'contact us',
      group: 'business help',
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    component: TermsAndConditions,
    meta: {
      allowAnonymous: true,
      staticContentPage: true,
      name: 'terms and conditions',
      group: 'business help',
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy,
    meta: {
      allowAnonymous: true,
      staticContentPage: true,
      name: 'privacy policy',
      group: 'business help',
    },
  },
  {
    path: '/cookies-policy',
    name: 'Cookies Policy',
    component: CookiesPolicy,
    meta: {
      allowAnonymous: true,
      staticContentPage: true,
      name: 'cookies policy',
      group: 'business help',
    },
  },
  {
    path: '/business-essentials',
    name: 'BusinessEssentials',
    component: BusinessEssentials,
    meta: {
      name: 'business essentials',
      group: 'business help',
    },
  },
  {
    path: '/business-essentials/:name',
    name: 'BusinessEssentialsDetails',
    component: BusinessEssentialsDetails,
    meta: {
      name: 'business essentials',
      group: 'business help',
    },
  },
  {
    path: '/account-security',
    name: 'AccountSecurity',
    component: AccountSecurity,
    meta: {
      name: 'account security',
      group: 'business help',
    },
  },
  {
    path: '/account-security/:name',
    name: 'AccountSecurityDetails',
    component: AccountSecurityDetails,
    meta: {
      name: 'account security',
      group: 'business help',
    },
  },
  {
    path: '/common-questions',
    name: 'CommonQuestions',
    component: CommonQuestions,
    meta: {
      name: 'common questions',
      group: 'business help',
    },
  },
  {
    path: '/common-questions/:name',
    name: 'CommonQuestionsDetails',
    component: CommonQuestionsDetails,
    meta: {
      name: 'common questions',
      group: 'business help',
    },
  },
]

export default routes
