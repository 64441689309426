import i18n from './i18n'

export const getFormatedDate = (date, pattern) => {
  return i18n.d(new Date(date), pattern)
}

export const getPreparedRoute = (route) => {
  return route.path.substring(1)
}

export const getIdFromSectionName = (sectionName) => {
  return sectionName.replace('-', '_')
}

export const getSitecoreResponse = (response) => {
  return response.sitecore.route.fields['Body Rich Text'].value
}

export const getSitecoreLayoutResponse = (response) => {
  return response.sitecore.route.placeholders['layout-service-only-main'][0]
    .fields.Children
}
