import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import routes from './routes'
import { beforeRouteEnterHook } from '@je-pc/auth'
import { trackPageTransition } from '@je-pc/tracking'
import { GET_STATIC_CONTENT } from '../store/actions.types'
import { getPreparedRoute } from '../helpers'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.meta.staticContentPage) {
    store.dispatch(GET_STATIC_CONTENT, getPreparedRoute(to))
  }
  beforeRouteEnterHook(to, from, next)
})

trackPageTransition(router)

export default router
